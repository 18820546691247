import "../styles/globals.css";
import "../styles/newStyle.css";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import 'react-toastify/dist/ReactToastify.css';
import Layout from "../components/layout/layout-main/layout";
import TagManager from "react-gtm-module";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import 'animate.css';
import { isMobile } from "react-device-detect";
import RequestModal from "../components/BlankModal/RequestModal";
// import * as ga from '../lib/ga'
const tagManagerArgs = {
  gtmId: "GTM-TJTGF26",
};


function MyApp({ Component, pageProps }) {

  const router = useRouter();
  const [count, setCount] = useState();
  const [mounted, setMounted] = useState(false)
  const [infoModal, setInfoModal] = useState(false);

  useEffect(() => {
    // if(router.asPath !== "/") {
    //   router.push("/");
    // }

    // return

    if (isMobile === true) {
      FreshworksWidget("destroy");
    }

    const localItems = [
      "selected-location",
      "membership",
      "insurance-val",
      "patientAddress",
    ];

    localItems.forEach((element) => {
      window.localStorage.removeItem(element);
    });

    if (router.asPath === "/checkout") {
      if (Cookies.get("cart") == undefined) {
        router.push("/")
      }
    }

    TagManager.initialize(tagManagerArgs);

    setMounted(true)
  }, []);

  useEffect(() => {
    if(router.query?.pricing_table === "true") {
      setInfoModal(true)
    }
  }, [router.query?.pricing_table]);

  return mounted && (
    <>
      <Layout count={count}>
        <Component {...pageProps} setCount={setCount} />
      </Layout>
      <RequestModal infoModal={infoModal} setInfoModal={setInfoModal}/>
    </>
  )

}

export default MyApp;
