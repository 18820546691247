import Link from "next/link";
import { useRouter } from "next/router";
import Image from 'next/image';
import {
  Modal,
  ModalBody,
} from "reactstrap";
import { useState } from "react";
import ShippingBoxIcon from "../../Icons/ShippingBoxIcon";

let medicationsList = [
  {
    title: "WHAT IS ZEPBOUND?",
    route: "/what-is-zepbound"
  },
  {
    title: "WHAT IS WEGOVY?",
    route: "/what-is-wegovy"
  },
  {
    title: "WHAT IS MOUNJARO?",
    route: "/what-is-mounjaro"
  },
  {
    title: "WHAT IS OZEMPIC?",
    route: "/what-is-ozempic"
  }
]

const DarkFooter = () => {
  const router = useRouter()
  const [refundModal, setRefundModal] = useState(false)

  return (
    <div className={router.pathname === "/contact_us" ? "dark-footer-container" : ""}>
      <div className="dark-footer-wrapper">
        <div className="row justify-content-center">
          <div className="col-md-3 container-dark-footer-logo">
            <div className="dark-footer-logo">
              <Image width={200} height={55} alt="Logo Footer" src="/images/logo_white.webp" loading="lazy" />
              {/* <p className="copyright-text">Copyright Southern Health Solutions, Inc.</p> */}
            </div>
            {/* <div className="footer_phone_new_main desktop-screen">
              <p className="footer_phone_new_p">National Eating Disorder <br />Association Hotline:</p>
              <p className="footer_phone_new_p2">(800) 931-2237</p>
            </div> */}

          </div>
          {/* <div className="row mt-3">
              <div className="col-md-4">
                {" "}
                <p className="dark-p-text">
                  <a href="/contact_us">
                    <div className="label-content position-relative main-change">
                      <div className={`support-msg-way newchange9`}>
                        <div className="ty-icon">
                          <i className="fa fa-phone" aria-hidden="true"></i>
                        </div>
                        <div>
                          <p className="p-val1">Contact Us</p>
                        </div>
                      </div>
                    </div>{" "}
                  </a>
                </p>
              </div>
              <div className="col-md-4">
                <div className="dark-social">
                  <a
                    href="https://www.instagram.com/next_medical/"
                    aria-label="Instagram"
                    target="_blank"
                    rel="nofollow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                      fill="white"
                    >
                      <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                    </svg>
                  </a>
                  <a
                    href="https://www.facebook.com/JoinNextMed/"
                    aria-label="Facebook"
                    target="_blank"
                    rel="nofollow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                      fill="white"
                    >
                      <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                    </svg>
                  </a>
                  <a
                    href="https://www.youtube.com/@joinnextmed"
                    aria-label="Youtube"
                    target="_blank"
                    rel="nofollow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 576 512"
                      fill="white"
                    >
                      <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                    </svg>
                  </a>
                </div>
              </div>

            </div>
            <hr /> */}
          <div className="col-md-3 d-flex flex-column justify-content-center">
            <p className="dark-p-text">Patient tools & resources</p>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="dark-list-wrapper">
                  <ul>
                    {/* <li>
                          <Link href="/login" target="_blank">
                            Login
                          </Link>
                        </li> */}
                    <li>
                      <Link
                        style={{ cursor: "pointer" }}
                        href="/privacy_policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <button
                        className="unstyled-buttons"
                        onClick={() => setRefundModal(true)}
                      >
                        Refund Policy
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 d-flex flex-column justify-content-center">
            <p className="dark-p-text">Contact us </p>
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="dark-list-wrapper">
                  <ul>
                    <li>
                      <div onClick={() => {
                        router.push({
                          pathname: '/login',
                          query: {
                            default: 'refill',
                          }
                        })
                      }}
                        className="label-content position-relative main-change"
                      >
                        <div className={`support-msg-way newchange9`}>
                          <div className="ty-icon">
                            <svg
                              fill="white"
                              width={20}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512">
                              <path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96v32V480H384V128 96 56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM96 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H96V96zM416 480h32c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H416V480zM224 208c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v48h48c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H288v48c0 8.8-7.2 16-16 16H240c-8.8 0-16-7.2-16-16V320H176c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h48V208z" />
                            </svg>
                          </div>
                          <div>
                            <p className="p-val1">Refill Medication</p>
                          </div>
                        </div>
                      </div>{" "}
                    </li>
                    <li>
                      <div onClick={() => {
                        router.push({
                          pathname: '/login',
                          query: {
                            tracking: true,
                          }
                        })
                      }}
                        className="label-content position-relative main-change"
                      >
                        <div className={`support-msg-way newchange9`}>
                          <div className="ty-icon">
                            <ShippingBoxIcon strokeWidth={"2"} stroke="#FAFAFA" width={22} />
                          </div>
                          <div>
                            <p className="p-val1">Track Shipment</p>
                          </div>
                        </div>
                      </div>{" "}
                    </li>
                    <li>
                      <div
                        className="label-content position-relative main-change"
                        onClick={() => {
                          router.push({
                            pathname: '/login',
                            query: {
                              default: 'membership',
                              next: "/cancellation"
                            }
                          })
                        }}
                      >
                        <div className={`support-msg-way newchange9`}>
                          <div className="ty-icon">
                            <svg
                              fill="white"
                              xmlns="http://www.w3.org/2000/svg"
                              width={20}
                              viewBox="0 0 448 512"
                            >
                              <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
                            </svg>
                          </div>
                          <div>
                            <p className="p-val1">Manage Membership</p>
                          </div>
                        </div>
                      </div>{" "}
                    </li>

                    <li>
                      <a href="mailto:team@joinnextmed.com">
                        <div className="label-content position-relative main-change">
                          <div className={`support-msg-way newchange9`}>
                            <div className="ty-icon">
                              <i
                                className="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div>
                              <p className="p-val1">Email US </p>
                            </div>
                          </div>
                        </div>{" "}
                      </a>
                    </li>
                    {/* <li>
                      <a href="tel:+18555501960">
                        <div className="label-content position-relative main-change">
                          <div className={`support-msg-way newchange9`}>
                            <div className="ty-icon">
                              <i
                                className="fa fa-phone"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div>
                              <p className="p-val1">+1-855-550-1960 </p>
                            </div>
                          </div>
                        </div>{" "}
                      </a>
                    </li> */}
                    <li>
                      <div className="label-content position-relative main-change">
                        <div className={`support-msg-way newchange9`}>
                          <div className="ty-icon">
                            <i
                              className="fa fa-clock-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div>
                            <p className="p-val1">24/7, 365</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-md-3 ">
                <p className="dark-p-text">About medications</p>
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="dark-list-wrapper">

                      <ul>
                        {
                          medicationsList.map((element, key) => (
                            <li key={key}>
                              <button className="unstyled-buttons text-white" onClick={() => router.push(element.route)}>
                                <div className="label-content position-relative main-change">
                                  <div className={`support-msg-way newchange9`}>
                                    <div className="ty-icon">
                                      <i
                                        className="fa fa-info-circle fa-1x"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    <div>
                                      <small className="p-val1 mx-3">{element.title}</small>
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>

                </div>
              </div> */}


            </div>
          </div>

          <div style={{ alignSelf: "center" }} className="col-md-2 col-12 col-file-data">
            <a href="https://legitscript.com" target="_blank" title="Verify LegitScript Approval">
              <Image src="/images/legitscript.webp"
                alt="LegitScript approved" width="140" height="100" border="0" loading="lazy" />
            </a>
          </div>
        </div>
      </div>
      <div className="dark-footer-banner-wrapper">
        <span>
          <Link href="HIPAA_notice">HIPAA notice</Link>
        </span>
        <span>
          <Link href="/npp"> Notice of Privacy Practices</Link>
        </span>
        <span>
          <Link href="/membership_agreement"> Membership Agreement</Link>
        </span>
        <span>
          <Link href="/capn">California Privacy Notice</Link>
        </span>
        <span>
          <Link href="terms_of_use">Terms of Services</Link>
        </span>
      </div>

      <Modal
        isOpen={refundModal}
        className="custom-modal success-model refund-policy w-100"
        centered
      >
        <ModalBody>
          <h4 className="success-p">Refund Policy</h4>

          <p className="success-span">
            {/* You may cancel your membership at any time through the "Account" page on your NextMed portal (<a href="https://nextmed.com/login">nextmed.com/login</a>) or by calling us at <a href="tel:+18555501960">(855) 550-1960</a>. */}
            You may cancel your membership at any time through the "Account" page on your NextMed portal (<a href="https://nextmed.com/login">nextmed.com/login</a>).
          </p>
          <p className="success-span">
            You may cancel your purchase within 48 hours of purchase if bought in error and receive a full refund. Refunds may take up to 10 business days to release. After 48 hours have passed, customers will not be eligible for a refund due to administrative and clinical services already being performed. Given the costs associated with lab testing, medical provider access, insurance services, and administrative work, we are unable to refund previously incurred monthly charges after they occur.
          </p>
          <button
            className="btn btn-lg-secondary float-right"
            onClick={(e) => {
              setRefundModal(false);
            }}
          >
            Close
          </button>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default DarkFooter;
