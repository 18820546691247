import { useEffect, useState } from 'react';
import {
    createSeamlesschexCustomer,
    createSubscriptionCustomer,
    createOnetimeSeamlesschexSales,
    cancelSubscriptionCustomerId,
    cancelSubscripcionByEmail
} from '../../lib/helper/seamlesschex';
import moment from 'moment';
import swal from 'sweetalert';
import { updateCustomerId } from '../../lib/helper/patient';
import ServicesModal from '../modal/ServicesModal';
import { Modal, ModalBody } from 'reactstrap';
import MembershipModal from '../modal/Membership';
import { toast } from "react-toastify";

const plans_key = {
    complete: "glp-complete-187",
    standard: "glp-standard-137"
}

const UpdateSeamlessCard = ({
    profile,
    failedCard,
    setPaymentConfirmSeamlessModalOpen,
    profilesValues
}) => {
    const [loading, setLoading] = useState(false)
    const [cardErr, setCardErr] = useState({
        ccnumber: null,
        ccexp: null,
        cvv: null
    })
    const [customerId, setCustomerId] = useState(null)
    const [subscriptionId, setSubscriptionId] = useState(null)

    // Local states for  trigger modals
    const [termsServiceModal, setTermsServiceModal] = useState(false)
    const [insuranceNotice, setInsuranceNotice] = useState(false)
    const [refundPolicyModal, setRefundPolicyModal] = useState(false)
    const [newWeightModal, setNewWeightModal] = useState(false)
    const [membershipModal, setMembershipModal] = useState(false)


    let userEmail;
    useEffect(() => {
        window.CollectJS.configure(
            {
                "paymentSelector": "#updateCard",
                variant: 'inline',
                styleSniffer: true,
                'validationCallback': function (field, status, message) {
                    switch (field) {
                        case "ccnumber":
                            setCardErr(prev => {
                                return {
                                    ...prev,
                                    ccnumber: status ? null : "Card number" + " is now Invalid: " + message
                                }
                            })
                            if (!status) {
                                // setLoading(false)
                            }
                            break;
                        case "ccexp":
                            setCardErr(prev => {
                                return {
                                    ...prev,
                                    ccexp: status ? null : "Card Expiration" + " is now Invalid: " + message
                                }
                            })
                            if (!status) {
                                // setLoading(false)
                            }
                            break;
                        case "cvv":
                            setCardErr(prev => {
                                return {
                                    ...prev,
                                    cvv: status ? null : "Cvv" + " is now Invalid: " + message
                                }
                            })
                            if (!status) {
                                // setLoading(false)
                            }

                        default:
                            setCardErr(prev => {
                                return {
                                    ...prev,
                                    ccnumber: null
                                }
                            })
                            break;
                    }
                },
                "timeoutDuration": 10000,
                "timeoutCallback": function () {
                    console.log("The tokenization didn't respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity");
                },
                "fieldsAvailableCallback": function () {
                    console.log("Collect.js loaded the fields onto the form");
                },
                callback: (token) => {
                    // setLoading(true)
                    submitPaymentRequest(token)
                },
                fields: {
                    ccnumber: {
                        placeholder: 'Card number',
                        selector: '#ccnumber'
                    },
                    ccexp: {
                        placeholder: 'MM / YY',
                        selector: '#ccexp'
                    },
                    cvv: {
                        placeholder: 'CVC',
                        selector: '#cvv'
                    },
                },
            }
        )

    }, [])

    const finishSubmit = (evt) => {
        if (evt) {
            evt.preventDefault();
            setLoading(true)
            window.CollectJS.startPaymentRequest()
        }
    }

    const submitPaymentRequest = async (cardToken) => {
        if (cardToken) {

            const payload = {
                payment_token: cardToken.token,
                ccnumber: cardToken.card.number,
                ccexp: cardToken.card.exp,
                first_name: profile?.patient_name?.split(" ")[0],
                last_name: profile?.patient_name?.split(" ")[profile?.patient_name?.split(" ").length - 1],
                email: window.sessionStorage.getItem("email"),
                phone: profile?.phone,
                zip_code: profile?.zip_code
            }

            if (cardToken.card.number) {
                const customer = await createSeamlesschexCustomer(payload);

                if (customer?.id) {

                    let plan = plans_key['standard']
                    if (
                        profile?.consumer_notes?.toLowerCase().includes("complete") ||
                        profile?.chief_complaint?.toLowerCase().includes("complete")
                    ) {
                        plan = plans_key['complete']
                    }


                    let salePayload = {
                        customer_vault_id: customer?.id,
                        amount: 1
                    }

                    if (profile?.consumer_notes?.toLowerCase().includes("complete") || profile?.chief_complaint?.toLowerCase().includes("complete")) {
                        salePayload.amount = 188
                    } else {
                        salePayload.amount = 138
                    }

                    const sale = await createOnetimeSeamlesschexSales(salePayload);
                    if (sale?.status == "success") {
                        await cancelSubscripcionByEmail({ email: window.sessionStorage.getItem("email") })
                        
                        let sub_payload = {
                            "plan_id": plan,
                            "start_date": moment().add("28", "days").format("YYYY-MM-DD"),
                            "customer_vault_id": customer?.id
                        }

                        const subscription = await createSubscriptionCustomer(sub_payload)
                        if (subscription?.status == "success") {
                            //Delete old subscriptions

                            //Finally update the customer id
                            await updateCustomerId({
                                email: window.sessionStorage.getItem("email"),
                                value: customer?.id
                            })
                            setPaymentConfirmSeamlessModalOpen(false)
                            setLoading(false)
                            toast.success("Subscription updated successfully!")
                            await profilesValues()
                        }
                    } else {
                        setLoading(false)
                        toast.error(`Something went wrong when charging your card with the amount of $${salePayload.amount} . Please try again later.`)
                    }


                }
                else {
                    setLoading(false)
                    toast.error("Something went wrong creating customer. Please try again later.")
                }
            }
        }
    }


    return (
        <>
            {failedCard &&
                <div className='mx-2 mb-4'>
                    <small>
                        <p className='m-auto'>
                            We're excited to keep helping you on your weight loss journey.
                        </p>
                        <p className='m-auto'>
                            We noticed <b>your subscription is not up to date </b>and need to add a new credit card on file. If you have any questions, please contact us{" "}
                            <a
                                href="/messages/patient"
                                className='text-primary'
                            >
                                here
                            </a>
                            .
                        </p>
                    </small>
                </div>
            }

            <form onSubmit={finishSubmit}>
                <div className="formInner">
                    <div id="payment-fields" className="seamless-fields">
                        <div id="ccnumber" className="seamless-fields-ccnumber"></div>
                        <div id="ccexp" className="seamless-fields-ccexp"></div>
                        <div id="cvv" className="seamless-fields-cvv"></div>
                    </div>
                </div>

                <div className={"place-order-btn mt-2"}>
                    <button type="submit" id="updateCard" onClick={(e) => { setLoading(true) }} disabled={cardErr.ccnumber !== null || cardErr.cvv != null || cardErr.ccexp != null || loading}
                        className="btn btn-lg-secondary">
                        <i className="fa fa-lock" aria-hidden="true"></i>{" "}{loading ? "Loading..." : "Confirm Payment Method"}
                    </button>
                    {cardErr.ccexp && (
                        <small className="text-danger cc-error-msg">{cardErr.ccexp}</small>
                    )}
                    {cardErr.ccnumber && (
                        <small className="text-danger cc-error-msg">{cardErr.ccnumber}</small>
                    )}
                    {cardErr.cvv && (
                        <small className="text-danger cc-error-msg">{cardErr.cvv}</small>
                    )}
                </div>
            </form>

            <Modal isOpen={newWeightModal} className="custom-modal success-model">
                <ModalBody>
                    <h4 className="success-p">
                        You may be eligible for a medical weight loss program with NextMed
                        based on the following guidelines:
                    </h4>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mt-2">
                                <span className="cart-list text-light-blue">
                                    <img src="/images/round-arrow.webp" />
                                    &nbsp; Your BMI is &#62;25
                                </span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mt-2">
                                <span className="cart-list text-light-blue">
                                    <img src="/images/round-arrow.webp" />
                                    &nbsp; You are not pregnant, lactating, or planning to become
                                    pregnant while on the medication
                                </span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mt-2">
                                <span className="cart-list text-light-blue">
                                    <img src="/images/round-arrow.webp" />
                                    &nbsp; You do not have a family history of thyroid cancer
                                </span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mt-2">
                                <span className="cart-list text-light-blue">
                                    <img src="/images/round-arrow.webp" />
                                    &nbsp; You do not have an active eating disorder
                                </span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mt-2">
                                <span className="cart-list text-light-blue">
                                    <img src="/images/round-arrow.webp" />
                                    &nbsp; You do not have active cancer, pancreatitis, or
                                    gallbladder disease
                                </span>
                            </div>
                        </div>
                    </div>
                    <button
                        className="btn btn-lg-secondary float-right"
                        onClick={(e) => {
                            setNewWeightModal(false);
                        }}
                    >
                        Close
                    </button>
                </ModalBody>
            </Modal>{" "}

            <Modal isOpen={refundPolicyModal} className="" centered toggle={() => setRefundPolicyModal(!refundPolicyModal)}>
                <ModalBody>
                    <h4 className="success-p">Refund Policy</h4>
                    <p
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            setNewWeightModal(true);
                        }}
                    >
                        Am I Eligible?
                    </p>
                    <p className="success-span">
                        If you would like to cancel your weight loss order, please email  <a href="mailto:team@joinnextmed.com">
                            {" "}
                            team@joinnextmed.com.
                        </a>{" "}
                    </p>
                    <p className="success-span">
                        You may cancel your purchase within 48 hours of purchase if bought in error and receive a full refund. Refunds may take up to 10 business days to release. After 48 hours have passed, customers will not be eligible for a refund due to administrative and clinical services already being performed. Given the costs associated with lab testing, medical provider access, insurance services, and administrative work, we are unable to refund previously incurred monthly charges after they occur.
                    </p>
                    <button
                        className="btn btn-lg-secondary float-right"
                        onClick={(e) => {
                            setRefundPolicyModal(false);
                        }}
                    >
                        Close
                    </button>
                </ModalBody>

            </Modal>
            <Modal isOpen={insuranceNotice} className="" centered toggle={() => setInsuranceNotice(!insuranceNotice)}>
                <ModalBody>
                    <h4 className="success-p">Insurance Notice</h4>
                    <p className="success-span">
                        Insurance coverage of individual weight loss drugs is not
                        guaranteed, although we will work with your insurance
                        company to get you approved.
                    </p>
                    <p className="success-span">
                        Out-of-pocket costs for Wegovy drugs depend on an individual's
                        insurance coverage. If your insurance company covers Wegovy,
                        copays can be as low as $25 per month. Without
                        commercial/employer insurance, certain Wegovy drugs are
                        significantly more expensive. Please note that certain Wegovy
                        drugs may not be approved by your insurance company if you are
                        not diabetic.
                    </p>
                    <p className="success-span">
                        Customers are required to complete an initial lab assessment
                        as part of the program in order to determine eligibility for
                        the program, and to provide documentation of eligibility to
                        insurance providers. All customers are responsible for copays
                        and deductibles as per individual insurance plans, in addition
                        to monthly payments for Next Medical services. Please check
                        with your insurance company if you have further questions.
                    </p>

                    <button
                        className="btn btn-lg-secondary float-right"
                        onClick={(e) => {
                            setInsuranceNotice((prevState) => !prevState);
                        }}
                    >
                        OK
                    </button>
                </ModalBody>
            </Modal>

            <MembershipModal modal={membershipModal} setModal={setMembershipModal} modalToggle={() => setMembershipModal(false)} />
            <ServicesModal modal={termsServiceModal} setModal={setTermsServiceModal} toggle={() => setTermsServiceModal(false)}></ServicesModal>
            {/* {!failedCard &&
                <div className='mt-4 mx-4'>
                    <small>

                        By placing your order via ACH, debit card, or credit card, you agree to Next Medical’s patient{` `}
                        <b style={{ cursor: 'pointer' }} onClick={() => setTermsServiceModal(true)}>Terms of Service, </b>{` `}
                        <b style={{ cursor: 'pointer' }} onClick={() => setInsuranceNotice(true)}>Insurance Notice </b>{` `}
                        and {` `}
                        <b style={{ cursor: 'pointer' }} onClick={() => setRefundPolicyModal(true)}>Refund Policy. </b>{` `}
                        If via ACH, you agree that future payments to Next Medical will be processed from the connected account. In order to cancel this authorization, you will reach out to Next Medical. The cost of medication, lab testing, and video doctor consults are not included with NextMed program costs. You further authorize NextMed to automatically charge your card $138 every four weeks according to our  <b style={{ cursor: 'pointer' }} onClick={() => setMembershipModal(true)}>  Membership Agreement  </b>{` `}


                    </small>
                </div>
            } */}

        </>
    );
};

export default UpdateSeamlessCard;
