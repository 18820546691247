import React from 'react'

import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
const BasicModal = (props) => {
    const { children, modal, setModal,heading ,mode="md"} = props
    return (
        <Modal isOpen={modal} toggle={(e) => setModal(false)} className="" size={mode} centered>
            <ModalHeader toggle={(e) => setModal(false)}>{heading}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>
    )
}

export default BasicModal