import { itemsValue101, itemsValue2, itemsValue4, itemsValue6, itemsValue7 } from "./itemsValues";


const MenuItems = ({ hideNav, navDisplay, setNavDisplay, activeMenu, menuVal }) => {
  return (
    <div className="menu-item-tests">

      {/* Mega Menu Start */}
      {hideNav == false ? (
        <div className="nav-item mega-menu-item d-none">
          <div className="mega-dropdown desktop-screen  ">
            <button
              className={
                navDisplay == true
                  ? "dropbtn drop1"
                  : "dropbtn"
              }
              onClick={() => {
                if (navDisplay == true) {
                  setNavDisplay(false);
                } else {
                  setNavDisplay(true);
                }
              }}
            >
              {" "}
              All Services{" "}
              <i
                className={
                  navDisplay == true
                    ? "fa fa-angle-up"
                    : "fa fa-angle-down"
                }
                aria-hidden="true"
              ></i>
            </button>
            {navDisplay == true ? (
              <div className="mega-content">
                <div className="container-megamenu">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mega-inner-menu">
                        <a
                          href="javascript:void(0)"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => activeMenu(1)}
                          className={
                            menuVal === 1
                              ? "mega-inner-menu2 tablinks active"
                              : "mega-inner-menu2 tablinks"
                          }
                        >
                          Allergies
                          <img src="/menu/icon_allergies.webp" />{" "}
                        </a>

                        <a
                          href="javascript:void(0)"
                          style={{ cursor: "pointer" }}
                          className={
                            menuVal === 2
                              ? "mega-inner-menu2 tablinks active"
                              : "mega-inner-menu2 tablinks"
                          }
                          onClick={(e) => activeMenu(2)}
                        >
                          Specialty Care
                          <img src="/menu/icon_womenshealth.webp" />
                        </a>

                        <a
                          href="javascript:void(0)"
                          style={{ cursor: "pointer" }}
                          className={
                            menuVal === 7
                              ? "mega-inner-menu2 tablinks active"
                              : "mega-inner-menu2 tablinks"
                          }
                          onClick={(e) => activeMenu(7)}
                        >
                          Weight Loss Programs
                          <img src="/menu/icon_weightloss.webp" />{" "}
                        </a>


                      </div>
                    </div>
                    <div className="col-md-9">

                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 1
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <Link href="/allergies#view-tests">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <a>36 Food Allergy Panel</a>
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-4">
                            <Link href="/allergies#view-tests">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <a>
                                  Indoor & Outdoor Allergy
                                </a>
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-4">
                            <Link href="/allergies#view-tests">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <a>Celiac Complete</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 4
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          {itemsValue4.map((item) => (
                            <div className="col-md-4">
                              <Link href={item.href}>
                                <div
                                  className="mega-inner-items"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <a>{item.text}</a>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 6
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          {itemsValue6.map((item) => (
                            <div className="col-md-4">
                              <Link href={item.href}>
                                <div
                                  className="mega-inner-items"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <h6>{item.text}</h6>
                                </div>
                              </Link>
                            </div>

                          ))}

                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 7
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <Link href="/weight_loss">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <p>
                                  GLP-1 Weight Loss
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-4">
                            <Link href="/weight_loss">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <p>
                                  GLP-1 Complete Program
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-4">
                            <Link href="/weight_loss_mw">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <p>
                                  Metformin Weight Loss
                                  Program
                                </p>
                              </div>
                            </Link>
                          </div>

                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 2
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <Link href="/womenhealth#view-tests">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <a>
                                  Female Hormone Complete
                                </a>
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-4">
                            <Link href="/womenhealth#view-tests">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <a>
                                  Female Hormone Standard
                                </a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 3
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <Link href="/menhealth#view-tests">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <a>Male Hormone Complete</a>
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-4">
                            <Link href="/menhealth#view-tests">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <a>Male Hormone Standard</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) :

        <div className="nav-item mega-menu-item d-none">
          <div className="mega-dropdown desktop-screen  ">
            {window.location.pathname == "/weight_loss/tiktok" ? "" :
              <button
                className={
                  navDisplay == true
                    ? "dropbtn drop1"
                    : "dropbtn"
                }
                onClick={() => {
                  if (navDisplay == true) {
                    setNavDisplay(false);
                  } else {
                    setNavDisplay(true);
                  }
                }}
              >
                <div className="login-navbar ">
                  <span
                    className="login"
                    style={{
                      color: "#363535",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    All Treatments{" "}
                    <i
                      className={
                        navDisplay == true
                          ? "fa fa-angle-up"
                          : "fa fa-angle-down"
                      }
                      aria-hidden="true"
                    ></i></span>
                </div>
              </button>
            }


            {navDisplay == true ? (
              <div className="mega-content">
                <div className="container-megamenu">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mega-inner-menu">
                        <a
                          href="javascript:void(0)"
                          style={{ cursor: "pointer" }}
                          className={
                            menuVal === 7
                              ? "mega-inner-menu2 tablinks active"
                              : "mega-inner-menu2 tablinks"
                          }
                          onClick={(e) => activeMenu(7)}
                        >
                          Weight Loss Programs
                          <img src="/menu/icon_weightloss.webp" />{" "}
                        </a>

                      </div>
                    </div>
                    <div className="col-md-9">

                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 7
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          {itemsValue7.map((item) => (
                            <div className="col-md-4">
                              <Link href={item.href}>
                                <div
                                  className="mega-inner-items"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <p>
                                    {item.text}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 4
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          {itemsValue4.map((item) => (
                            <div className="col-md-4">
                              <Link href={item.href}>
                                <div
                                  className="mega-inner-items"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <a>{item.text}</a>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 6
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">

                          {itemsValue6.map((item) => (
                            <div className="col-md-4">
                              <Link href={item.href}>
                                <div
                                  className="mega-inner-items"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <h6>{item.text}</h6>
                                </div>
                              </Link>
                            </div>

                          ))}

                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 100
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <Link href="/testosterone">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <p>
                                  Testosterone Standard Program
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-4">
                            <Link href="/testosterone">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <p>
                                  Testosterone Complete Program
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 101
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          {itemsValue101.map((item) => (
                            <div className="col-md-4">
                              <Link href={item.href}>
                                <div
                                  className="mega-inner-items"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <p>
                                    {item.text}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 2
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          {itemsValue2.map((item) => (
                            <div className="col-md-4">
                              <Link href={item.href}>
                                <div
                                  className="mega-inner-items"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <h6>{item.text}</h6>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          setNavDisplay(false);
                        }}
                        className={
                          menuVal == 3
                            ? "second-item-menu-data tabcontent show-mega-menu"
                            : "hide-mega-menu"
                        }
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <Link href="/menhealth#view-tests">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <a>Male Hormone Complete</a>
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-4">
                            <Link href="/menhealth#view-tests">
                              <div
                                className="mega-inner-items"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <a>Male Hormone Standard</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>}
      {/* Mega Menu End */}
    </div>)
}

export default MenuItems;