import common from "../../api/common";
import axios from "axios";
import { getPatientInfo } from "./patient";
import axiosApiInstance from "../auth/authService";


export const createBelugaVisit = async (data, staging) => {
  try {
    let config = {
      method: "post",
      url: `${common.api_url}/beluga-create-visit-fe`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  
    if(staging){
      config.url = `${common.api_url}/beluga-create-visit-fe?staging=${staging}`;
    }
    
    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
    
  } catch (error) {
    return null
  }
}

export const belugaSubmitPhoto = async (data, staging=false) => {
  try {
    let config = {
      method: "post",
      url: `${common.api_url}/beluga-submit-photo`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  
    if(staging){
      config.url = `${common.api_url}/beluga-submit-photo?staging=${staging}`;
    }
  
    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (error) {
    return null
  }
}

export const addQuestionsToBeluga = async (data) => {
  let config = {
    method: "post",
    url: `${common.api_url}/beluga-add-question`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
}

export const getBelugaQuestions = async (data) => {
  let config = {
    method: "post",
    url: `${common.api_url}/beluga-get-questions`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axiosApiInstance(config);
  if (resp && resp.data) {
    return resp.data;
  } else {
    return null;
  }
}

export const getBelugaMessages = async (data) => {
    var config = {
      method: "post",
      url: `${common.api_url}/beluga-get-messages`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const resp = await axios(config);
    if (resp && resp.data) {
      return resp;
    } else {
      return null;
    }
  };

export const sendBelugaMessages = async (data) => {
    var config = {
      method: "post",
      url: `${common.api_url}/beluga-send-chat-message`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp;
    } else {
      return null;
    }
  };

  export const getLastBelugaVisit = async (data) => {
    try {
      let config = {
        method: "get",
        url: `${common.api_url}/get-last-visit-id?mrn=${data}`,
        headers: {
          "Content-Type": "application/json",
        },
      }
      const response = await axiosApiInstance(config);
      if (response && response.data) {
        return response.data;
      }
      return null
    } catch (error) {
      return null
    }

  }

  export const getBelugaPrescriptions = async (data) => {
    var config = {
      method: "post",
      url: `${common.api_url}/beluga-get-prescriptions`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  }