import React, { useState, useEffect } from 'react'
import { Layout, Menu } from 'antd';
import Link from 'next/link'
import Cookies from "js-cookie"
import {
    UserOutlined,
    ExperimentOutlined,
    LoginOutlined,
    CommentOutlined, MessageOutlined,
    CreditCardOutlined
} from '@ant-design/icons';
import { useRouter } from 'next/router';
import { getPatientProfile } from '../../lib/helper/patient';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import VisitCreateCardUnused from "../createCard/visitCreateCardUnused";
import { getBelugaMessages } from '../../lib/helper/beluga';
import { Badge } from "antd"
import { getSubscriptionIdByEmail_seamlesschex } from '../../lib/helper/seamlesschex';
import UpdateSeamlessCard from '../seamlesschex/UpdateSeamlessCard';

const { Sider, Content } = Layout;

const Sidebar = (props) => {


    // STRIPE CONFIG
    const stripeOptions = { fonts: [{ cssSrc: "https://fonts.googleapis.com/css2?family=Jost&display=swap" }] };
    const stripePromise = loadStripe(
        "pk_live_51MuGnTH7sZ29UScpdbl17QRi8U4xsgXQgRPmC38RIhDPpDoK7434w00xhLaUf7GxrtxNA7Qx5R4VjLOYa9nCdt1N00gTogBY3l"
    );


    const router = useRouter()

    const [paymentConfirmModalOpen, setPaymentConfirmModalOpen] = useState(false)
    const [desktopData, setDesktopData] = useState([])
    const [verify, setVerify] = useState(false)
    const [showBadge, setShowBadge] = useState(true)
    const [ready, setReady] = useState(false)
    const [paymentConfirmSteamlessModalOpen, setPaymentConfirmSeamlessModalOpen] = useState(false)
    const [failedCard, setFailedCard] = useState(false)
    const [profile, setProfile] = useState({})

    const currentPath = router.pathname;
    const activeKey = desktopData.find(item => item.route === currentPath)?.key;

    useEffect(async () => {
        setTimeout(() => {
            setReady(true)
        }, 1000)
        fetchProfile()
        if (router.pathname == "/conversation") {
            setShowBadge(false)
            Cookies.set("checkConversation", "true")
            setReady(false)
            setReady(true)
            setDesktopData(msgdata)
        }
    }, [router.pathname, router.query?.intake])

    const with_msg = [
        // {
        //     key: '5',
        //     icon: (<CreditCardOutlined />),
        //     label: (<a onClick={() => setPaymentConfirmModalOpen(true)}>Payment Method</a>)
        // },
        {
            key: '1',
            icon: <Link href="/visits"><ExperimentOutlined /></Link>,
            route: "/visits",
            label: (<Link href="/visits">Treatments</Link>),
        },
        {
            key: '2',
            route: "/information",
            icon: (<Link href="/information"><UserOutlined /></Link>),
            label: (<Link href="/information">Account</Link>),
        },
        {
            key: '4',
            icon: (<LoginOutlined />),
            isLogout: true,
            label: (<a onClick={() => handleLogout()}>Logout</a>),
        },

    ]

    const without_msg = [
        // {
        //     key: '5',
        //     icon: (<CreditCardOutlined />),
        //     label: (<a onClick={() => setPaymentConfirmModalOpen(true)}>Payment Method</a>)
        // },
        {
            key: '1',
            icon: <Link href="/visits"><ExperimentOutlined /></Link>,
            route: "/visits",
            label: (<Link href="/visits">Treatments</Link>),
        },
        {
            key: '2',
            route: "/information",
            icon: (<Link href="/information"><UserOutlined /></Link>),
            label: (<Link href="/information">Account</Link>),
        },
        {
            key: '3',
            route: "/messages/patient",
            icon: (
                <Link href="/messages/patient">
                    <span className='support-icon'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                            fill="currentColor"
                        >
                            <path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" />
                        </svg>

                    </span>
                </Link>
            ),
            label: (<Link href="/messages/patient">Support</Link>),
        },
        {
            key: '4',
            icon: (<LoginOutlined />),
            isLogout: true,
            label: (<a onClick={() => handleLogout()}>Logout</a>),
        },
    ]

    /*
    
    */

    const msgdata = [
        // {
        //     key: '5',
        //     icon: (<CreditCardOutlined />),
        //     label: (<a onClick={() => setPaymentConfirmModalOpen(true)}>Payment Method</a>)
        // },
        {
            key: '1',
            icon: <Link href="/visits"><ExperimentOutlined /></Link>,
            route: "/visits",
            label: (<Link href="/visits">Treatments</Link>),
        },
        {
            key: '4',
            route: "Messages",
            icon: (
                <>
                    {
                        showBadge ? (
                            <Link href="/conversation">
                                <Badge
                                    style={{ minWidth: '10px', height: '12px', fontSize: '7px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    count={"!"}
                                    color='blue'
                                    offset={[-11, 0]}
                                >
                                    <MessageOutlined />
                                </Badge>
                            </Link>
                        ) : (
                            <Link href="/conversation">
                                <MessageOutlined />
                            </Link>
                        )
                    }
                </>
            ),
            label: (<Link href="/conversation">
                {
                    showBadge ? (
                        <span style={{ marginLeft: '9px', fontWeight: '500' }}>Doctor Messages</span>
                    ) : (
                        <span style={{ fontWeight: '500' }}>Doctor Messages</span>
                    )
                }
            </Link>),
        },
        {
            key: '2',
            route: "/information",
            icon: (<Link href="/information"><UserOutlined /></Link>),
            label: (<Link href="/information">Account</Link>),
        },
        {
            key: '3',
            route: "/messages/patient",
            icon: (
                <Link href="/messages/patient">
                    <span className='support-icon'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                            fill="currentColor"
                        >
                            <path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" />
                        </svg>

                    </span>
                </Link>
            ),
            label: (<Link href="/messages/patient">Support</Link>),
        },
        {
            key: '5',
            icon: (<LoginOutlined />),
            isLogout: true,
            label: (<a onClick={() => handleLogout()}>Logout</a>),
        },


    ]

    const handleLogout = () => {
        window.sessionStorage.clear();

        Object.keys(Cookies.get()).forEach((element) => {
            Cookies.remove(element);
        })

        router.push("/login");
    }
    const fetchProfile = async () => {
        const profile = await getPatientProfile()
        if (profile) {
            setProfile(profile)
            if (profile.is_healthie == "2" && profile.is_user_verified == null && (router.pathname != "/cancellation" && router.pathname != "/messages/patient")) {
                setVerify(false)
                router.push({
                    pathname: "/information",
                    query: {
                        ...router.query,
                        page: "1",
                        intake: true
                    }
                })
            } else {
                if (parseInt(profile.is_healthie) == 1) {
                    if (profile.schedule_followup_visit != 1 && profile.first_visit != 1) {
                        setVerify(false)

                    } else {
                        setVerify(true)
                        setDesktopData(with_msg)
                    }
                } else {
                    if (profile?.is_healthie == 2) {
                        const response = await getBelugaMessages({
                            "mrn": profile?.mrn
                        })

                        if (response?.data?.data?.length > 0) {
                            const lastSender = response?.data?.data[response?.data?.data?.length - 1]
                            if (lastSender?.sender_type == "doctor") {

                                if (
                                    Cookies.get('lastMessageId') &&
                                    (
                                        parseInt(Cookies.get("lastMessageId")) != lastSender.id ||
                                        Cookies.get("checkConversation") != "true"
                                    )
                                ) {
                                    Cookies.set("showBadge", "true")
                                    Cookies.set("lastMessageId", lastSender.id)
                                    setShowBadge(true)
                                }
                                else if (!Cookies.get('lastMessageId') && Cookies.get("checkConversation") != "true") {
                                    Cookies.set("showBadge", "true")
                                    Cookies.set("lastMessageId", lastSender.id)
                                    setShowBadge(true)
                                }
                                else {
                                    setShowBadge(false)
                                    Cookies.set("showBadge", "false")
                                    Cookies.set("lastMessageId", lastSender.id)
                                }
                            } else {
                                Cookies.set("showBadge", "false")
                                setShowBadge(false)
                            }
                            setDesktopData(msgdata)
                        } else {
                            setDesktopData(without_msg)
                        }
                        setVerify(true)
                    }
                }

                if (profile?.is_user_verified == null && (profile?.schedule_first_visit == 0 || profile?.schedule_first_visit == 2) && router.query?.intake) {
                    setVerify(false)
                    router.push({
                        pathname: "/information",
                        query: {
                            ...router.query
                        }
                    })
                }
            }

            if (
                router.pathname != "/messages/patient" &&
                router.pathname != "/cancellation"
            ) {
                await checkSeamlessStatus(profile?.email)
            }
        }else{
            window.sessionStorage.clear();
            router.push("/login");
        }
    }

    const checkSeamlessStatus = async (email) => {
        const subcriptions = await getSubscriptionIdByEmail_seamlesschex({ email: email })
        if (subcriptions?.subscriptions?.length) {
            let lastSub = subcriptions?.subscriptions[subcriptions?.subscriptions?.length - 1]
            if (lastSub?.status != "active") {
                setPaymentConfirmSeamlessModalOpen(true)
                setFailedCard(true)
            }
        } else {
            setPaymentConfirmSeamlessModalOpen(true)
            setFailedCard(true)
        }
    }

    return (
        <>
            <Modal isOpen={paymentConfirmModalOpen} size={"lg"} centered toggle={() => setPaymentConfirmModalOpen(false)}>
                <ModalHeader>
                    Update Account Information
                </ModalHeader>
                <ModalBody>
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <VisitCreateCardUnused onlyConfirm={true} />
                    </Elements>
                </ModalBody>


            </Modal>
            <Layout>
                {
                    verify === true && (
                        <Sider trigger={null} theme="light" style={{ position: "fixed", height: "100vh", background: "#FFFFFF" }} className="desktop-screen main-side-bar-1 " width="250">
                            <div className="logo img-logos mt-3" >
                                <img src="/images/logo.webp" className="sidebar-logo-ant" />
                                <hr />
                            </div>
                            {
                                ready && (
                                    <Menu
                                        theme="light"
                                        mode="inline"
                                        defaultSelectedKeys={[activeKey]}
                                        style={{
                                            fontSize: "17px",
                                            fontWeight: "400",
                                            background: "#ffffff"

                                        }}
                                        items={desktopData}
                                    />
                                )
                            }
                        </Sider>
                    )
                }
                <Layout className="site-layout main-bg-site">
                    <Content
                        className={`${verify === true ? "sidebar-antd-size" : "sidebar-antd-size-verify"} ${currentPath === "/messages/patient" && ("new-message-ui")}`}
                    >
                        {props.children}
                        {
                            verify === true && (
                                <div className="sidebar-menu-mobile mobile-view-1">
                                    {
                                        desktopData.map((ele) => {
                                            if (ele?.isLogout) {
                                                {/* I do this because the handleLogout function call does not work correctly when mapping it. */ }
                                                return (
                                                    <div key={ele.key} className={`${activeKey === ele.key && ("active-menu")} Mobile-menu-child`} onClick={handleLogout}>
                                                        {ele.icon}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div key={ele.key} className={`${activeKey === ele.key && ("active-menu")} Mobile-menu-child`}>
                                                        {ele.icon}
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>)
                        }
                    </Content>
                </Layout>
            </Layout>

            <Modal isOpen={paymentConfirmSteamlessModalOpen} size={"lg"} centered>
                <ModalHeader>
                    <h6 className="h5">Please enter a new payment method</h6>
                </ModalHeader>
                <ModalBody>
                    <UpdateSeamlessCard
                        profile={profile}
                        failedCard={failedCard}
                        setPaymentConfirmModalOpen={setPaymentConfirmModalOpen}
                        setPaymentConfirmSeamlessModalOpen={setPaymentConfirmSeamlessModalOpen}
                        profilesValues={fetchProfile}
                    />
                </ModalBody>
            </Modal>
        </>

    )
}

export default Sidebar