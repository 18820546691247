const itemsValue2 = [
  {
    href: "/thyroid",
    text: 'Female Hormone Testing'
  },
  {
    href: "/womenhealth#view-tests",
    text: 'Female Hormone Complete'
  },
  {
    href: "/womenhealth#view-tests",
    text: 'Female Hormone Standard'
  },
  {
    href: "/thyroid",
    text: 'Energy'
  },
  {
    href: "/thyroid#view-tests",
    text: 'Thyroid Standard'
  },
  {
    href: "/thyroid#view-tests",
    text: 'Thyroid Complete'
  },
  {
    href: "/vitamin",
    text: 'Vitamin Testing'
  },
  {
    href: "/vitamin#view-tests",
    text: 'Vitamin B Panel'
  },
  {
    href: "/vitamin#view-tests",
    text: 'Vitamin D & Inflammation Test'
  },
  {
    href: "/heart_health",
    text: 'Heart Health'
  },
  {
    href: "/heart_health#view-tests",
    text: 'Hemoglobin A1C Test'
  },
  {
    href: "/heart_health#view-tests",
    text: 'Cholesterol & Lipids Panel'
  },
]
const itemsValue4 = [
  {
    href: "/std#view-tests",
    text: 'STD Basic'
  },
  {
    href: "/std#view-tests",
    text: 'STD Standard'
  },
  {
    href: "/std#view-tests",
    text: 'STD Complete'
  },
  {
    href: "/herpes#view-tests",
    text: 'Herpes Standard'
  },
  {
    href: "/herpes#view-tests",
    text: 'Herpes Complete'
  },
  {
    href: "/hiv#view-tests",
    text: 'HIV Standard'
  },
  {
    href: "/hiv#view-tests",
    text: 'HIV Complete'
  },
  {
    href: "/lymedisease#view-tests",
    text: 'Lyme Disease Test'
  },


]
const itemsValue6 = [
  {
    href: "/thyroid",
    text: 'Energy'
  },
  {
    href: "/thyroid#view-tests",
    text: 'Thyroid Standard'
  },
  {
    href: "/thyroid#view-tests",
    text: 'Thyroid Complete'
  },
  {
    href: "/sleep_stress",
    text: 'Sleep Stress'
  },
  {
    href: "/sleep_stress#view-tests",
    text: 'Sleep & Stress Hormone Test'
  },
  {
    href: "/sleep_stress#view-tests",
    text: 'Cortisol Test'
  },
  {
    href: "/heart_health",
    text: 'Heart Health'
  },

  {
    href: "/heart_health#view-tests",
    text: 'Hemoglobin A1C Test'
  },
  {
    href: "/heart_health#view-tests",
    text: 'Cholesterol & Lipids Panel'
  },
  {
    href: "/womenhealth",
    text: 'Female Hormone Testing'
  },
  {
    href: "/womenhealth#view-tests",
    text: 'Female Hormone Complete'
  },
  {
    href: "/womenhealth#view-tests",
    text: 'Female Hormone Standard'
  },
  {
    href: "/menhealth",
    text: 'Male Hormone Testing'
  },
  {
    href: "/menhealth",
    text: 'Male Hormone Testing'
  },
  {
    href: "/menhealth#view-tests",
    text: 'Male Hormone Complete'
  },
  {
    href: "/menhealth#view-tests",
    text: 'Male Hormone Standard'
  },

]

const itemsValue7 = [
  {
    href: "/weight_loss",
    text: 'GLP-1 Weight Loss'
  },
  {
    href: "/weight_loss",
    text: 'GLP-1 Weight Loss Complete'
  },
  {
    href: "/weight_loss/medication",
    text: 'Medication'
  },
]

const itemsValue101 = [
  {
    href: "/anti_aging",
    text: 'Metformin Anti-Aging Program'
  },
  {
    href: "/anti_aging",
    text: 'Rapamycin Anti-Aging Program'
  },
  {
    href: "/anti_aging",
    text: 'Rapamycin Anti-Aging Complete'
  },
]
export { itemsValue2, itemsValue4, itemsValue6, itemsValue7, itemsValue101 };